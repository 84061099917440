export const GA = {
  EVENT_NAME: {
    get_started_btn: 'get_started_btn_clk',
    view_demo_btn: 'view_demo_btn_clk',
    insta_login_btn: 'insta_login_btn_clk',
    dm_not_working_fix_btn: 'dm_not_working_fix_btn',
    hard_reload_home_btn: 'hard_reload_home_btn',
    hard_reload_post_list_btn: 'hard_reload_post_list_btn',
    view_more_home_btn: 'view_more_home_btn',
    enable_auto_dm_btn: 'enable_auto_dm_btn',
    edit_auto_dm_btn: 'edit_auto_dm_btn',
    select_template_card: 'select_template_card',
    post_insights_btn: 'post_insights_btn',
    country_update_btn: 'country_update_btn',
    first_audo_dm_btn: 'first_audo_dm_btn',
    conversation_page_viewed: 'conversation_page_viewed',
    add_next_step_btn: 'add_next_step_btn',
    delete_step_btn: 'delete_step_btn',
    done_step_btn: 'done_step_btn',
    set_live_btn: 'set_live_btn',
    delete_automation_btn: 'delete_automation_btn',
  },
  CATEGORY: {
    button_click: 'button_click',
    card_click: 'card_click',
    page_viewed: 'page_viewed',
  },
};
