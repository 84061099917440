import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyA1PM6-CWhOiSRDx-S1tv1TE6fHcMIBiJM',
  authDomain: 'texthauler.firebaseapp.com',
  projectId: 'texthauler',
  storageBucket: 'texthauler.appspot.com',
  messagingSenderId: '730732742265',
  appId: '1:730732742265:web:75c8d11a1c2e16215d36a8',
  measurementId: 'G-X6ZFSLED3W',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { analytics };
