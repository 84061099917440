import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { SENTRY } from './constants/sentry';
// ----------------------------------------------------------------------
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY.DSN,
    tracesSampleRate: SENTRY.SAMPLE_RATE,
    release: process.env.NODE_ENV,
    replaysSessionSampleRate: 0.8,
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
