import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// DASHBOARD: GENERAL
export const GeneralAppPage = Loadable(lazy(() => import('../pages/dashboard/GeneralAppPage')));
export const AutoDmsPage = Loadable(lazy(() => import('../pages/dashboard/GeneralAutoDmsPage')));
export const UserAccountPage = Loadable(lazy(() => import('../pages/dashboard/UserAccountPage')));
export const AutoDmPage = Loadable(lazy(() => import('../pages/auto-dm/AutoDm')));
export const PricingPage = Loadable(lazy(() => import('../pages/PricingPage')));
export const BulkDmPage = Loadable(lazy(() => import('../pages/bulk-dm/BulkDm')));
export const AutoDmTypeBtnPage = Loadable(
  lazy(() => import('../pages/auto-dm-type-btn/AutoDmTypeBtn'))
);
export const AutomateConversationPage = Loadable(
  lazy(() => import('../pages/automate-conversation/AutomateConversationPage'))
);

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
export const FaqsPage = Loadable(lazy(() => import('../pages/FaqsPage')));
export const AboutPage = Loadable(lazy(() => import('../pages/AboutPage')));
export const Contact = Loadable(lazy(() => import('../pages/ContactPage')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));
export const PrivacyPolicyPage = Loadable(lazy(() => import('../pages/PrivacyPolicy')));
export const TnCPage = Loadable(lazy(() => import('../pages/TnCPage')));
