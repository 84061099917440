import { Helmet } from 'react-helmet-async';
// sections
import Login from '../../sections/auth/Login';
import { BRAND } from '../../config-global';

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <>
      <Helmet>
        <title>{`Login | ${BRAND.name}`}</title>
      </Helmet>

      <Login />
    </>
  );
}
