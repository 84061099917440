import { Plans } from '../config/plans';

export const getPlanName = (plan) => {
  switch (plan) {
    case Plans.FREE_TIER:
      return 'Free Tier';
    case Plans.ONE_MONTH:
      return 'Unlimited';
    case Plans.SIX_MONTH:
      return 'Unlimited';
    default:
      return '';
  }
};

export const getPlanDurationName = (plan) => {
  switch (plan) {
    case Plans.FREE_TIER:
      return 'Free Tier';
    case Plans.ONE_MONTH:
      return '1 Month';
    case Plans.SIX_MONTH:
      return '6 Months';
    default:
      return '';
  }
};
