import { useCallback } from 'react';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../config/firebaseConfig';

function useAnalytics() {
  const trackEvent = useCallback((eventName, eventParams = {}) => {
    if (!analytics) {
      console.warn('Firebase Analytics is not initialized.');
      return;
    }
    try {
      logEvent(analytics, eventName, eventParams);
    } catch (error) {
      console.error('Error logging event: ', error);
    }
  }, []);

  return { trackEvent };
}

export default useAnalytics;
