const isLocalhost = () => {
  const hostname = window.location.hostname;
  const localNetworkRegex = /^192\.168\.\d{1,3}\.\d{1,3}$/;

  return (
    hostname === 'localhost' ||
    hostname === '127.0.0.1' ||
    hostname === '::1' ||
    localNetworkRegex.test(hostname)
  );
};

export default isLocalhost;
