import React, { useEffect, useState } from 'react';
// @mui
import Select from 'react-select';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material';

import { _countries } from '../../constants/countries';
import { useAuthContext } from '../../auth/useAuthContext';
import axiosInstance from '../../utils/axios';
import { POST_COUNTRY_API } from '../../config/endpoints';
import useAnalytics from '../../hooks/useAnalytics';
import { GA } from '../../constants/ga-events';

// ----------------------------------------------------------------------

export default function CountryPicker() {
  const [open, setOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [isLoading, setIsloading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthContext();
  const { trackEvent } = useAnalytics();
  const country_prev = user?.country;

  const setCountryData = async () => {
    try {
      const response = await fetch(
        'https://valid.layercode.workers.dev/list/countries?format=select&flags=true'
      );
      const data = await response.json();
      if (data?.countries) {
        setCountries(data?.countries);
        setSelectedCountry(data.userSelectValue);
      } else {
        setCountries(_countries);
      }
    } catch (error) {
      setCountries(_countries);
    }
  };

  const updateCountry = async (country) => {
    setIsloading(true);
    try {
      await axiosInstance.post(POST_COUNTRY_API, { country });
      enqueueSnackbar('Country added successfully!');
      setOpen(false);
      window.location.reload();
    } catch (error) {
      console.error(error);
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    if (!country_prev) {
      setOpen(true);
    }
  }, [country_prev]);

  useEffect(() => {
    setCountryData();
  }, []);

  const handleCountryUpdate = () => {
    if (selectedCountry?.value) {
      updateCountry(selectedCountry?.value);
      trackEvent(GA.EVENT_NAME.country_update_btn, {
        category: GA.CATEGORY.button_click,
        label: selectedCountry?.value,
      });
    }
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle sx={{ pb: 2 }}>Select Country</DialogTitle>

      <DialogContent sx={{ typography: 'body2', height: '40vh' }}>
        <Select
          options={countries}
          value={selectedCountry}
          onChange={(selectedOption) => setSelectedCountry(selectedOption)}
        />
      </DialogContent>

      <DialogActions>
        <LoadingButton
          onClick={handleCountryUpdate}
          type="submit"
          variant="contained"
          loading={isLoading}
          disabled={isLoading}
        >
          Done
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
