import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  VerifyCodePage,
  // Dashboard: General
  GeneralAppPage,
  AutoDmsPage,
  AutoDmPage,
  PricingPage,
  UserAccountPage,
  BulkDmPage,
  AutoDmTypeBtnPage,
  AutomateConversationPage,

  // MAIN
  Page500,
  Page403,
  Page404,
  HomePage,
  FaqsPage,
  AboutPage,
  Contact,
  ComingSoonPage,
  MaintenancePage,
  PrivacyPolicyPage,
  TnCPage,

  //
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          element: <CompactLayout />,
          children: [
            {
              path: 'verify',
              element: (
                <GuestGuard>
                  <VerifyCodePage />
                </GuestGuard>
              ),
            },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralAppPage /> },
        { path: 'account', element: <UserAccountPage /> },
        { path: 'auto-dms', element: <AutoDmsPage /> },
        { path: 'auto-dm/:postId', element: <AutoDmPage /> },
        { path: 'auto-dm-type-btn/:postId', element: <AutoDmTypeBtnPage /> },
        { path: 'automate-conversation', element: <AutomateConversationPage /> },

        { path: 'pricing', element: <PricingPage /> },
        { path: 'bulk-dm', element: <BulkDmPage /> },
      ],
    },

    // Main Routes
    {
      element: (
        <GuestGuard>
          <MainLayout />
        </GuestGuard>
      ),
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <AboutPage /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'privacy', element: <PrivacyPolicyPage /> },
        { path: 'tos', element: <TnCPage /> },
      ],
    },

    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
